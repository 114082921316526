@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.2/font/bootstrap-icons.css");

.section {
	height: 100vh;
  display: flex;
}

.section-free-height {
	padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
}

.animated-gradient {
  background: linear-gradient(-30deg, #a7ffa3, #b2aaff, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 10s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.center-centric {
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.top-left-centric {
  padding-top: 50px;
  justify-content: flex-start;
  align-items: flex-start;
}

.evenly-sized {
  align-items: stretch;
}

.vertical-groups {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.horizontal-groups {
  display: flex;
  flex-direction: row;
}

.maroon {
  color:rgb(250, 250, 250);
  background-color: #800000;
}

.green {
  background-color: #d2ffd0;
}

.experience {
  background-color: rgb(183, 226, 226);
}

.experience h2, .experience h3 {
  color: rgb(65, 65, 65);
}

.contact {
  background-color: rgb(247, 229, 215);
}

.contact .boxed {
  filter: drop-shadow(20px 20px 4px rgb(221, 220, 192));
}

.contact h4, .contact a{
  text-decoration: none;
}

.item {
  padding: 20px;
  margin: 20px;
  width: 100%;
}

.boxed {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
}

.green .boxed {
  filter: drop-shadow(20px 20px 4px #9fd6b8);
}

.stack {
  flex-wrap: nowrap;
}

.snippet {
  border-radius: 20px;
  background-color: white;
  display: flex;
  margin-bottom: 40px;
  filter: drop-shadow(20px 20px 4px rgb(151, 187, 204));
}

.snippet .image img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  object-fit: cover;
  overflow: hidden;
}

.snippet .image {
  width: 40%;
}

.snippet .detail {
  width: 60%;
  padding: 30px;
}

.detail-eye-candy {
  display: flex;
  justify-content: space-between;
}

.detail-eye-candy .logos {
  place-self: flex-start;
}

.detail-eye-candy .logos img{
  margin-right: 10px;
}

.detail-eye-candy .buttons {
  place-self: flex-end;
}

.images {
  display: flex;
  justify-content: space-evenly;
}

.introduction {
  color: rgb(250, 250, 250);
}

.introduction button {
  margin-right: 10px;
}

nav {
  position: absolute;
  height: 50px;
  margin-top: -50px;
  background: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.5);
}

.active p {
  color: rgb(250, 250, 250) !important;
}

nav p {
  color: rgb(201, 201, 201);
  margin: 0px;
}

nav p:hover {
  color: rgb(250, 250, 250);
  transition: 0.4s;
}

footer {
  background-color: rgb(43, 61, 42);
  color:rgb(250, 250, 250);
  min-height: 200px;
  display: flex;
  align-items: center;
}

footer .footer-columns {
  display: flex;
  justify-content: space-evenly;
}

footer .about {
  display: flex;
  flex-direction: column;
}

footer .links {
  display: flex;
  flex-direction: column;
}

footer .contact {
  display: flex;
  flex-direction: column;
}

footer a {
  color: rgb(201, 201, 201);
  text-decoration: none;
}

footer p {
  color: rgb(201, 201, 201);
}

footer .text {
  color: rgb(250, 250, 250);
}

footer a:hover {
  color: rgb(250, 250, 250);
  transition: 0.4s;
}

footer p:hover {
  color: rgb(250, 250, 250);
  transition: 0.4s;
}

.pdf-viewer {
  width: 100%;
  height: 800px;
}

.resume-viewer {
  width: 100%;
  height: 100%;
}
